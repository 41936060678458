import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  ConfirmOrderPayload,
  ConfirmOrderResponsePayload,
  DeclineOrderPayload,
  DeclineOrderResponsePayload,
  FetchOrdersRequestPayload,
  GetOrderItemDetailsResponsePayload,
  GetOrdersResponsePayload,
  GetTotalActiveOrderValueResponsePayload,
  GetTotalOrderResponsePayload,
  OngoingOrderResponsePayload,
  TotalNumberOfActiveOrderResponsePayload,
  TotalOrderPerDayPayload,
  TotalOrderPerDayResponsePayload,
  TotalOrdersWithinDateRangeResponsePayload,
  TotalPlacedOrderResponsePayload,
  TotalPlacedOrderWithDatePayload,
} from '../order_types';
import { OrderRepository } from '../repositories/order.repo';

export class OrderService {
  constructor(private readonly orderRepo: OrderRepository) {}

  async getActiveOrders(arg?: PaginationPayload): Promise<GetOrdersResponsePayload> {
    const getActiveOrdersResponse = await this.orderRepo.getActiveOrders(arg);
    return getActiveOrdersResponse;
  }

  async getTotalNumberOfActiveOrders(): Promise<TotalNumberOfActiveOrderResponsePayload> {
    const getTotalNumberOfActiveOrdersResponse = await this.orderRepo.getTotalNumberOfActiveOrders();
    return getTotalNumberOfActiveOrdersResponse;
  }

  async confirmOrder(payload: ConfirmOrderPayload): Promise<ConfirmOrderResponsePayload> {
    const confirmOrderResponse = await this.orderRepo.confirmOrder(payload);
    return confirmOrderResponse;
  }

  async declineOrder(payload: DeclineOrderPayload): Promise<DeclineOrderResponsePayload> {
    const declineOrderResponse = await this.orderRepo.declineOrder(payload);
    return declineOrderResponse;
  }

  async getOrderDetails(payload: ConfirmOrderPayload): Promise<GetOrderItemDetailsResponsePayload> {
    const getOrderDetailsResponse = await this.orderRepo.getOrderDetails(payload);
    return getOrderDetailsResponse;
  }

  async getOngoingOrders(arg?: PaginationPayload): Promise<OngoingOrderResponsePayload> {
    const getOngoingOrdersResponse = await this.orderRepo.getOngoingOrders(arg);
    return getOngoingOrdersResponse;
  }

  async getOrderHistory(arg?: PaginationPayload): Promise<GetOrdersResponsePayload> {
    const getOrderHistoryResponse = await this.orderRepo.getOrderHistory(arg);
    return getOrderHistoryResponse;
  }

  async getOrderReady(payload: ConfirmOrderPayload): Promise<ConfirmOrderResponsePayload> {
    const getOrderReadyResponse = await this.orderRepo.getOrderReady(payload);
    return getOrderReadyResponse;
  }

  async getPendingOrders(payload: FetchOrdersRequestPayload): Promise<GetOrdersResponsePayload> {
    const getPendingOrdersResponse = await this.orderRepo.getPendingOrders(payload);
    return getPendingOrdersResponse;
  }

  async getTotalOrderPerDay(payload: TotalOrderPerDayPayload): Promise<TotalOrderPerDayResponsePayload> {
    const getTotalOrderPerDayResponse = await this.orderRepo.getTotalOrderPerDay(payload);
    return getTotalOrderPerDayResponse;
  }

  async getTotalPlacedOrders(): Promise<TotalPlacedOrderResponsePayload> {
    const getTotalPlacedOrdersResponse = await this.orderRepo.getTotalPlacedOrders();
    return getTotalPlacedOrdersResponse;
  }

  async getTotalOrdersWithinDateRange(
    payload: TotalPlacedOrderWithDatePayload
  ): Promise<TotalOrdersWithinDateRangeResponsePayload> {
    const getTotalOrdersWithinDateRangeResponse = await this.orderRepo.getTotalOrdersWithinDateRange(payload);
    return getTotalOrdersWithinDateRangeResponse;
  }

  async getTotalOrders(): Promise<GetTotalOrderResponsePayload> {
    const response = await this.orderRepo.getTotalOrders();
    return response;
  }

  async getOrders(payload: FetchOrdersRequestPayload): Promise<GetOrdersResponsePayload> {
    const response = await this.orderRepo.getOrders(payload);
    return response;
  }

  async getTotalActiveOrderValue(): Promise<GetTotalActiveOrderValueResponsePayload> {
    const response = await this.orderRepo.getTotalActiveOrderValue();
    return response;
  }
}
