import React from 'react';
import ReactDOM from 'react-dom/client';

import { ErrorBoundary } from 'react-error-boundary';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import { matchRoutes, RouterProvider } from 'react-router-dom';
import {
  initializeFaro,
  createReactRouterV6DataOptions,
  ReactIntegration,
  getWebInstrumentations,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './assets/styles/index.css';
import { browserRouter } from './App';
import reportWebVitals from './reportWebVitals';

import { ChakraProvider } from '@chakra-ui/react';
import theme from './chakra.config';
import ErrorFallBackUI from './components/errors/error_fallback_UI';

import { Provider } from 'react-redux';
import { store } from './redux/app/store';

const queryClient = new QueryClient();

initializeFaro({
  url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/82eb712bcd9c948ebc9a929951594c45',
  app: {
    name: 'foodbag.vendor',
    version: '0.11.20',
    environment: process.env?.REACT_APP_ENVIRONMENT,
  },

  instrumentations: [
    // Mandatory, omits default instrumentations otherwise.
    ...getWebInstrumentations(),

    // Tracing package to get end-to-end visibility for HTTP requests.
    new TracingInstrumentation(),

    // React integration for React applications.
    new ReactIntegration({
      router: createReactRouterV6DataOptions({
        matchRoutes,
      }),
    }),
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <ErrorBoundary
            FallbackComponent={ErrorFallBackUI}
            onError={() => console.log('An error occurred!')}
            onReset={() => {
              window.location.reload();
            }}
          >
            <RouterProvider router={browserRouter} />
          </ErrorBoundary>
        </ChakraProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
